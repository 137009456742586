define("organic-shopping-shop-client/templates/authenticated/order-history/order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HrEXm2ni",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"suborder-details\",null,[[\"suborder\"],[[24,[\"model\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/order-history/order.hbs"
    }
  });

  _exports.default = _default;
});