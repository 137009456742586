define("organic-shopping-shop-client/routes/authenticated/payments/connect-to-stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var user = this.get("currentUser.user");
      return user.get("shop").then(function (shop) {
        return shop.get("stripeState").then(function (stripeState) {
          if (stripeState && stripeState.get("id") != null) {
            return stripeState;
          } else {
            return _this.createStripeState();
          }
        });
      });
    },
    createStripeState: function createStripeState() {
      var stripeState = this.store.createRecord("stripe-state");
      stripeState.set("shop", this.get("currentUser.user.shop"));
      return stripeState.save();
    }
  });

  _exports.default = _default;
});