define("organic-shopping-shop-client/models/product", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string", {
      readOnly: true
    }),
    createdAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    updatedAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    priceNetCents: _emberData.default.attr("number"),
    priceNetCurrency: _emberData.default.attr("string"),
    descr: _emberData.default.attr("string"),
    visible: _emberData.default.attr("boolean"),
    deleted: _emberData.default.attr("boolean", {
      defaultValue: false
    }),
    publishedAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    emptyAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    inventory: _emberData.default.attr("number", {
      defaultValue: 0
    }),
    vatCode: _emberData.default.attr("number"),
    productVariants: _emberData.default.hasMany("product-variant", {
      async: true
    }),
    images: _emberData.default.hasMany("image", {
      async: true
    }),
    imageMain: _emberData.default.belongsTo("image", {
      readOnly: true
    }),
    shop: _emberData.default.belongsTo("shop", {
      async: true
    }),
    category: _emberData.default.belongsTo("category", {
      async: true,
      readOnly: true
    }),
    brand: _emberData.default.belongsTo("brand", {
      async: true
    }),
    productType: _emberData.default.belongsTo("productType", {
      readOnly: true
    }),
    priceNet: Ember.computed("priceNetCents", {
      get: function get() {
        var centPrice = this.priceNetCents;

        if (centPrice) {
          return centPrice / 100;
        }

        return centPrice;
      },
      set: function set(_key, newPrice) {
        this.set("priceNetCents", Number.parseInt((Number.parseFloat(newPrice, 10) * 100).toFixed(0), 10));
        return newPrice;
      }
    })
  });

  _exports.default = _default;
});