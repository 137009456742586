define("organic-shopping-shop-client/components/accept-stripe-terms", ["exports", "organic-shopping-shop-client/validations/stripe-terms"], function (_exports, _stripeTerms) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    StripeTermsValidations: _stripeTerms.default,
    formModel: Ember.computed(function () {
      return Ember.Object.create({});
    }),
    actions: {
      submit: function submit() {
        var stripeAccount = this.store.createRecord("stripe-account");
        stripeAccount.set("termsAcceptedAt", new Date());
        stripeAccount.set("shop", this.get("currentUser.user.shop"));
        return stripeAccount.save();
      }
    }
  });

  _exports.default = _default;
});