define("organic-shopping-shop-client/models/shop-signup", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    phone: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    userEmail: _emberData.default.attr("string"),
    password: _emberData.default.attr("string")
  });

  _exports.default = _default;
});