define("organic-shopping-shop-client/models/suborder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    order: _emberData.default.belongsTo("order", {
      readOnly: true
    }),
    shop: _emberData.default.belongsTo("shop"),
    status: _emberData.default.attr("string", {
      readOnly: true
    }),
    triggerEvent: _emberData.default.attr("string"),
    items: _emberData.default.hasMany("order-item", {
      async: true,
      inverse: "suborder"
    }),
    shipments: _emberData.default.hasMany("shipment", {
      async: true
    }),
    productItems: Ember.computed("items.@each.{shippingSku,checked}", function () {
      return this.items.filterBy("shippingSku.content", null);
    }),
    shippingItems: Ember.computed("items.@each.productVariant", function () {
      return this.items.filterBy("productVariant.content", null);
    }),
    isPaid: Ember.computed.equal("status", "paid"),
    isPacking: Ember.computed.equal("status", "packing"),
    isPacked: Ember.computed.equal("status", "packed"),
    isShipped: Ember.computed.equal("status", "shipped"),
    showShipments: Ember.computed.or("isPacked", "isShipped")
  });

  _exports.default = _default;
});