define("organic-shopping-shop-client/templates/components/bg-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tW71I7gK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"image__inner\"],[11,\"data-discount-text\",[22,\"discountText\"]],[8],[14,1],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/bg-image.hbs"
    }
  });

  _exports.default = _default;
});