define("organic-shopping-shop-client/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    female: _emberData.default.attr("boolean"),
    male: _emberData.default.attr("boolean"),
    position: _emberData.default.attr("number"),
    visible: _emberData.default.attr("boolean"),
    createdAt: _emberData.default.attr("date"),
    updatedAt: _emberData.default.attr("date"),
    productCount: _emberData.default.attr("number"),
    products: _emberData.default.hasMany({
      async: true
    })
  });

  _exports.default = _default;
});