define("organic-shopping-shop-client/models/product-type", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr("string"),
    category: _emberData.default.belongsTo(),
    unitDescription: _emberData.default.attr("string"),
    syncedFromProvider: _emberData.default.attr("boolean"),
    products: _emberData.default.belongsTo("product", {
      async: true
    })
  });

  _exports.default = _default;
});