define("organic-shopping-shop-client/templates/components/accept-stripe-terms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0FAV1vwg",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"accept_stripe_terms.accepting_terms_and_conditions\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"validated-form\",null,[[\"model\",\"on-submit\"],[[28,\"changeset\",[[24,[\"formModel\"]],[24,[\"StripeTermsValidations\"]]],null],[28,\"action\",[[23,0,[]],\"submit\"],null]]],{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        \"],[1,[28,\"t\",[\"accept_stripe_terms.accepting_terms_1\"],null],false],[7,\"a\",true],[10,\"href\",\"https://stripe.com/connect-account/legal\"],[8],[1,[28,\"t\",[\"accept_stripe_terms.accepting_terms_2\"],null],false],[9],[0,\".\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `f.input` to be a contextual component but found a string. Did you mean `(component f.input)`? ('organic-shopping-shop-client/templates/components/accept-stripe-terms.hbs' @ L13:C8) \"],null]],[[\"type\",\"label\",\"name\",\"required\"],[\"checkbox\",[28,\"t\",[\"accept_stripe_terms.accepted_terms_and_conditions\"],null],\"terms\",true]]],false],[0,\"\\n      \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"submit\"]],\"expected `f.submit` to be a contextual component but found a string. Did you mean `(component f.submit)`? ('organic-shopping-shop-client/templates/components/accept-stripe-terms.hbs' @ L14:C8) \"],null]],[[\"label\"],[[28,\"t\",[\"accept_stripe_terms.continue\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/accept-stripe-terms.hbs"
    }
  });

  _exports.default = _default;
});