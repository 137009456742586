define("organic-shopping-shop-client/routes/authenticated/new-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        product: this.store.createRecord("product"),
        shop: this.get("currentUser.user").get("shop")
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.product,
        shop: model.shop
      });
    },
    actions: {
      willTransition: function willTransition() {
        if (this.get("controller.model.isNew")) {
          this.store.unloadRecord(this.get("controller.model"));
        }

        return true;
      }
    }
  });

  _exports.default = _default;
});