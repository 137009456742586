define("organic-shopping-shop-client/controllers/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    intercom: Ember.inject.service(),
    actions: {
      invalidateSession: function invalidateSession() {
        this.intercom.shutdown();
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});