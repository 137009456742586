define("organic-shopping-shop-client/router", ["exports", "organic-shopping-shop-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route("login"); // all routes that require the session to be authenticated

    this.route("authenticated", {
      path: ""
    }, function () {
      this.route("dashboard", {
        path: ""
      });
      this.route("products", function () {
        this.route("product", {
          path: "/:id"
        });
      });
      this.route("new-product", {
        path: "/products/new"
      });
      this.route("order-history", function () {
        this.route("order", {
          path: "/:id"
        });
      });
      this.route("suborders", {
        path: "/orders"
      }, function () {
        this.route("suborder", {
          path: "/:id"
        });
      });
      this.route("payments", function () {
        this.route("connect-to-stripe");
        this.route("stripe-callback");
      });
      this.route("settings");
    });
    this.route("signup");
    this.route("not-found");
    this.route("four-oh-four", {
      path: "/*path"
    });
  });
  var _default = Router;
  _exports.default = _default;
});