define("organic-shopping-shop-client/templates/components/suborder-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vuJ3JYur",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"width: 70px\"],[10,\"class\",\"mr-2\"],[8],[0,\"\\n    \"],[7,\"h2\",true],[8],[0,\"#\"],[1,[24,[\"suborder\",\"id\"]],false],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[24,[\"suborder\",\"user\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"suborder_card.items\"],[[\"suborder_count\"],[[24,[\"suborder\",\"items\",\"length\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[8],[0,\"\\n  \"],[1,[24,[\"suborder\",\"status\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/suborder-card.hbs"
    }
  });

  _exports.default = _default;
});