define("organic-shopping-shop-client/controllers/authenticated/settings", ["exports", "organic-shopping-shop-client/validations/shop"], function (_exports, _shop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ShopValidations: _shop.default,
    resetPasswordURL: window.location.protocol + "//" + window.location.host + "/backend/users/password/new",
    actions: {
      submit: function submit(model) {
        model.save();
      }
    }
  });

  _exports.default = _default;
});