define("organic-shopping-shop-client/routes/authenticated/payments/stripe-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    queryParams: {
      code: {
        replace: true
      },
      state: {
        replace: true
      }
    },
    model: function model(params) {
      var _this = this;

      this.get("currentUser.user").get("shop").then(function (shop) {
        var account = _this.store.createRecord("stripe-account", {
          authorizationCode: params.code,
          state: params.state,
          shop: shop
        });

        return account.save();
      });
    },
    afterModel: function afterModel() {
      var _this2 = this;

      Ember.run.later(function () {
        _this2.transitionTo("authenticated.payments");
      }, 5000);
    }
  });

  _exports.default = _default;
});