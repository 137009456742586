define("organic-shopping-shop-client/templates/not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wHeQHqPE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row w-100 m-0\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"w-100 text-center\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"not_found.page_not_found\"],null],false],[0,\"!\"],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"not_found.there_is_nothing_here\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/not-found.hbs"
    }
  });

  _exports.default = _default;
});