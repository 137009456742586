define("organic-shopping-shop-client/templates/components/dashboard-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uaBnE/ts",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-chart\",null,[[\"type\",\"height\",\"data\",\"options\"],[\"line\",150,[28,\"hash\",null,[[\"datasets\"],[[28,\"array\",[[28,\"hash\",null,[[\"label\",\"borderColor\",\"backgroundColor\",\"pointStyle\",\"data\",\"lineTension\"],[\"Sales\",\"#007bff\",\"rgba(0, 123, 255, 0.4)\",\"rectRounded\",[28,\"array\",[200,500,1100,3000,5000,8000],null],0]]],[28,\"hash\",null,[[\"label\",\"borderColor\",\"backgroundColor\",\"pointStyle\",\"borderDash\",\"data\",\"lineTension\"],[\"Sales\",\"#007bff\",\"rgba(0, 123, 255, 0.1)\",\"rectRounded\",[28,\"array\",[5],null],[28,\"array\",[null,null,null,null,null,8000,1200],null],0]]]],null]]]],[28,\"hash\",null,[[\"legend\",\"animation\",\"tooltips\",\"scales\"],[[28,\"hash\",null,[[\"display\"],[false]]],[28,\"hash\",null,[[\"duration\"],[500]]],[28,\"hash\",null,[[\"cornerRadius\"],[4]]],[28,\"hash\",null,[[\"xAxes\",\"yAxes\"],[[28,\"array\",[[28,\"hash\",null,[[\"type\",\"labels\",\"gridLines\"],[\"category\",[28,\"array\",[\"January\",\"February\",\"March\",\"April\",\"May\",\"June\",\"July\"],null],[28,\"hash\",null,[[\"display\"],[false]]]]]]],null],[28,\"array\",[[28,\"hash\",null,[[\"scaleLabel\",\"gridLines\",\"ticks\"],[[28,\"hash\",null,[[\"display\",\"labelString\"],[false,\"Revenue\"]]],[28,\"hash\",null,[[\"drawBorder\"],[false]]],[28,\"hash\",null,[[\"maxTicksLimit\",\"callback\"],[4,[28,\"action\",[[23,0,[]],\"labelFormatter\"],null]]]]]]]],null]]]]]]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/dashboard-chart.hbs"
    }
  });

  _exports.default = _default;
});