define("organic-shopping-shop-client/controllers/signup", ["exports", "organic-shopping-shop-client/validations/signup"], function (_exports, _signup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    SignupValidations: _signup.default,
    actions: {
      submit: function submit(changeset) {
        var _this = this;

        changeset.save().then(function (result) {
          _this.transitionToRoute("login", {
            queryParams: {
              email: result.get("userEmail")
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});