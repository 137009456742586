define("organic-shopping-shop-client/models/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdBy: _emberData.default.belongsTo("user"),
    updatedBy: _emberData.default.belongsTo("user"),
    user: _emberData.default.belongsTo("user"),
    status: _emberData.default.attr("string"),
    invoiceAddress: _emberData.default.belongsTo("user-address"),
    deliveryAddress: _emberData.default.belongsTo("user-address"),
    paymentSelection: _emberData.default.attr("number"),
    storeCard: _emberData.default.attr("boolean", {
      readOnly: true
    }),
    stripeSumCents: _emberData.default.attr("number"),
    stripeSumCurrency: _emberData.default.attr("string"),
    items: _emberData.default.hasMany("order-item", {
      async: true
    }),
    suborders: _emberData.default.hasMany("suborder", {
      async: true
    }),
    stripeSum: Ember.computed("stripeSumCents", function () {
      return this.stripeSumCents / 100;
    }),
    productItems: Ember.computed("items.@each.shippingSku", function () {
      return this.items.filterBy("shippingSku.content", null);
    }),
    shippingItems: Ember.computed("items.@each.productVariant", function () {
      return this.items.filterBy("productVariant.content", null);
    })
  });

  _exports.default = _default;
});