define("organic-shopping-shop-client/adapters/application", ["exports", "ember-data", "organic-shopping-shop-client/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    host: _environment.default.host,
    namespace: "api",
    authorize: function authorize(xhr) {
      var _this$get = this.get("session.data.authenticated"),
          access_token = _this$get.access_token;

      xhr.setRequestHeader("Authorization", "Bearer ".concat(access_token));
    }
  });

  _exports.default = _default;
});