define("organic-shopping-shop-client/models/order-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    createdBy: _emberData.default.belongsTo("user", {
      readOnly: true
    }),
    updatedBy: _emberData.default.belongsTo("user", {
      readOnly: true
    }),
    status: _emberData.default.attr("string", {
      readOnly: true
    }),
    order: _emberData.default.belongsTo("order", {
      readOnly: true
    }),
    suborder: _emberData.default.belongsTo("suborder", {
      readOnly: true
    }),
    shop: _emberData.default.belongsTo("shop", {
      readOnly: true
    }),
    productVariant: _emberData.default.belongsTo("product-variant", {
      async: true
    }),
    shippingSku: _emberData.default.belongsTo("shipping-sku", {
      async: true
    }),
    countCustomer: _emberData.default.attr("number", {
      readOnly: true
    }),
    countShop: _emberData.default.attr("number"),
    countSystem: _emberData.default.attr("number", {
      readOnly: true
    }),
    priceGrossCents: _emberData.default.attr("number", {
      readOnly: true
    }),
    priceGrossCurrency: _emberData.default.attr("string", {
      readOnly: true
    }),
    returnDeadline: _emberData.default.attr("date", {
      readOnly: true
    }),
    settledAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    trackingNumber: _emberData.default.attr("string", {
      readOnly: true
    }),
    triggerEvent: _emberData.default.attr("string"),
    checked: false,
    priceNetCents: _emberData.default.attr("number", {
      readOnly: true
    }),
    priceNetCurrency: _emberData.default.attr("string", {
      readOnly: true
    }),
    productItem: Ember.computed("productVariant.id", function () {
      return !!this.belongsTo("productVariant").id();
    }),
    shippingItem: Ember.computed("shippingSku.id", function () {
      return !!this.belongsTo("shippingSku").id();
    }),
    didLoad: function didLoad() {
      this.checked = this.status == "packed";
    }
  });

  _exports.default = _default;
});