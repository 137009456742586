define("organic-shopping-shop-client/templates/authenticated/payments/connect-to-stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rjzr2FoW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-6\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"payments.connect_to_stripe.connect_to_stripe\"],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[0,\"\\n      \"],[1,[28,\"t\",[\"payments.connect_to_stripe.info_line1\"],null],false],[0,\"\\n      \"],[1,[28,\"t\",[\"payments.connect_to_stripe.info_line2\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[22,\"stripeConnectExpressUrl\"]],[10,\"class\",\"stripe-connect\"],[8],[7,\"span\",true],[8],[1,[28,\"t\",[\"payments.connect_to_stripe.connect_with_stripe\"],null],false],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/payments/connect-to-stripe.hbs"
    }
  });

  _exports.default = _default;
});