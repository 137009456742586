define("organic-shopping-shop-client/components/add-box-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    selectedSuborder: null,
    isLoading: false,
    isError: false,
    actions: {
      addBox: function addBox(suborder) {
        var _this = this;

        this.set("isLoading", true);
        var shipment = this.store.createRecord("shipment", {
          suborder: suborder
        });
        shipment.save().then(function () {
          _this.set("selectedSuborder", null);

          _this.set("isLoading", false);
        }).catch(function () {
          _this.set("isLoading", false);

          _this.set("isError", true);
        });
      },
      closeAddBoxModal: function closeAddBoxModal() {
        this.set("isError", false);
        this.set("selectedSuborder", null);
      }
    }
  });

  _exports.default = _default;
});