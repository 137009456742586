define("organic-shopping-shop-client/routes/four-oh-four", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function redirect(model, transition) {
      var url = this._router.location.formatURL("/not-found");

      if (window.location.pathname !== url) {
        transition.abort();
        return this.intermediateTransitionTo("not-found");
      }
    }
  });

  _exports.default = _default;
});