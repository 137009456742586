define("organic-shopping-shop-client/controllers/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service("session"),
    queryParams: ["email"],
    email: null,
    resetPasswordURL: window.location.protocol + "//" + window.location.host + "/backend/users/password/new",
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var email = this.email,
            password = this.password;
        this.session.authenticate("authenticator:oauth2", email, password, ["employee", "default"]).catch(function (reason) {
          var message = reason.error || reason;

          if (message === "invalid_grant") {
            message = "Access denied. Invalid credentials, or user is not an employee.";
          }

          _this.set("errorMessage", message);
        });
      }
    }
  });

  _exports.default = _default;
});