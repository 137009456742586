define("organic-shopping-shop-client/models/product-variant", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    product: _emberData.default.belongsTo("product"),
    createdBy: _emberData.default.belongsTo("user"),
    updatedBy: _emberData.default.belongsTo("user"),
    inventory: _emberData.default.attr("number"),
    priceNetCents: _emberData.default.attr("number"),
    priceNetCurrency: _emberData.default.attr("string"),
    productVariantProperties: _emberData.default.hasMany("product-variant-property")
  });

  _exports.default = _default;
});