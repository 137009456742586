define("organic-shopping-shop-client/routes/authenticated/suborders/suborder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(_ref) {
      var id = _ref.id;
      return this.store.findRecord("suborder", id);
    }
  });

  _exports.default = _default;
});