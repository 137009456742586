define("organic-shopping-shop-client/models/shipping-sku", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    shippingProduct: _emberData.default.belongsTo("shipping-product"),
    shop: _emberData.default.belongsTo("shop"),
    stripeId: _emberData.default.attr("string")
  });

  _exports.default = _default;
});