define("organic-shopping-shop-client/validations/stripe-terms", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    terms: [(0, _validators.validateInclusion)({
      list: [true],
      message: "Please accept the terms and conditions!"
    })]
  };
  _exports.default = _default;
});