define("organic-shopping-shop-client/routes/authenticated/order-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    infinity: Ember.inject.service(),
    model: function model() {
      return this.infinity.model("suborder", {
        perPage: 5,
        startingPage: 1,
        perPageParam: "page[size]",
        pageParam: "page[number]",
        totalPagesParam: "meta.page-count",
        include: "items.product-variant.product,items.shipping-sku,order.user",
        filter: {
          status: "shipped"
        }
      });
    }
  });

  _exports.default = _default;
});