define("organic-shopping-shop-client/components/suborder-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLoading: false,
    store: Ember.inject.service(),
    actions: {
      checkStatusChanged: function checkStatusChanged(item) {
        item.toggleProperty("checked");
        this.set("isLoading", true);
        this.send("saveItem", item);
      },
      checkAll: function checkAll() {
        var _this = this;

        var items = this.suborder.productItems;
        items.forEach(function (item) {
          if (!item.checked) {
            item.set("checked", true);

            _this.set("isLoading", true);

            _this.send("saveItem", item);
          }
        });
      },
      saveItem: function saveItem(item) {
        var _this2 = this;

        if (item.checked) {
          item.set("triggerEvent", "item_packed");
        } else {
          item.set("triggerEvent", "item_unpacked");
        }

        item.save().then(function () {
          var suborder = _this2.suborder;
          var items = suborder.get("productItems");
          var anyChecked = items.any(function (item) {
            return item.checked;
          });
          var everyChecked = items.every(function (item) {
            return item.checked;
          });

          if (everyChecked) {
            if (suborder.get("status") !== "packed") {
              suborder.set("triggerEvent", "finished_packing");
            }
          } else if (anyChecked) {
            if (suborder.get("status") !== "packing") {
              suborder.set("triggerEvent", "begin_packing");
            }
          } else {
            if (suborder.get("status") !== "paid") {
              suborder.set("triggerEvent", "reset_packing");
            }
          }

          if (suborder.get("hasDirtyAttributes")) {
            suborder.save().then(function () {
              if (suborder.status === "packed") {
                if (!suborder.shipments.length) {
                  var shipment = _this2.store.createRecord("shipment", {
                    suborder: suborder
                  });

                  shipment.save();
                }
              }

              _this2.set("isLoading", false);
            });
          } else {
            _this2.set("isLoading", false);
          }
        });
      },
      orderShipped: function orderShipped() {
        var suborder = this.suborder;
        suborder.set("triggerEvent", "order_shipped");
        suborder.save();
      }
    }
  });

  _exports.default = _default;
});