define("organic-shopping-shop-client/serializers/category", ["exports", "organic-shopping-shop-client/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serializeAttribute: function serializeAttribute(snapshot, json, key) {
      if (snapshot.attr(key) != null) {
        this._super.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});