define("organic-shopping-shop-client/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accept_stripe_terms": {
      "accepted_terms_and_conditions": "I accepted the Stripe Connect terms and conditions",
      "accepting_terms_1": "To receive payments, you need to accept the ",
      "accepting_terms_2": "Stripe Connect terms and conditions",
      "accepting_terms_and_conditions": "Accepting terms and conditions",
      "continue": "Continue"
    },
    "application": {
      "header": {
        "logout": "Log out",
        "orders": "Orders",
        "payments": "Payments",
        "products": "Products",
        "settings": "Settings",
        "shipped_orders": "Shipped orders",
        "shop_name": "{shopName}"
      }
    },
    "dashboard": {
      "fullfilled": "Fulfilled",
      "good_morning": "Good morning",
      "header": "Some things that are going on today",
      "recent_orders": "Recent orders",
      "see_all": "See all",
      "shipments": "Shipments",
      "to_pack": "To pack",
      "today's_revenue": "Today's revenue"
    },
    "login": {
      "email_placeholder": "Enter username",
      "forgot_your_password": "Forgot your password?",
      "login": "Login",
      "login_title": "Farm admin login",
      "password": "Password",
      "password_placeholder": "Enter password",
      "username": "Username"
    },
    "new_product_form": {
      "back": "Back",
      "create_product": "Create product",
      "inventory": "Inventory",
      "price": "Price",
      "price_per_unit": "per {unitDescription}"
    },
    "not_found": {
      "page_not_found": "Page not found",
      "there_is_nothing_here": "There is nothing here."
    },
    "order_history": {
      "empty_order": "No orders yet.",
      "shipped_orders": "Shipped orders"
    },
    "payments": {
      "connect_to_stripe": {
        "connect_to_stripe": "Connect to Stripe",
        "connect_with_stripe": "Connect with Stripe",
        "info_line1": "Before you can start accepting payments, you need to input your payout information.",
        "info_line2": "We use Stripe as our payment partner. Through Stripe we accept payments by credit card, Apple Pay and more."
      },
      "index": {
        "go_to_stripe_dashboard": "Payment settings are handled through Stripe. Click the link below to go to your Stripe Dashboard.",
        "manage_stripe_account": "Manage Stripe account",
        "payment_settings": "Payment settings"
      },
      "stripe_callback": {
        "info_line1": "You're stripe account is created, and you can now begin accepting payments!",
        "info_line2": "Redirecting you back to payment settings...",
        "title": "You're all set!"
      }
    },
    "product_card": {
      "inventory_and_variants": "{inventory} left, {variant_count} variants"
    },
    "product_details": {
      "description": "Description",
      "inventory": "Inventory",
      "price": "Price",
      "published": "Published",
      "save_changes": "Save changes"
    },
    "products": {
      "add_new_product": "Add new product",
      "delete_product": {
        "cancel": "Cancel",
        "confirm": "Confirm",
        "delete": "Delete",
        "please_confirm": "Please Confirm"
      },
      "empty_product": "No products added yet.",
      "items": "{inventory} items",
      "new_product": {
        "add_product": "Add product"
      },
      "new_product_button": "New product",
      "per_item": "per item",
      "products": "Products"
    },
    "settings": {
      "company_email": "Company email",
      "company_name": "Company name",
      "company_phone_number": "Company phone number",
      "forgot_your_password": "Forgot your password?",
      "save": "Save",
      "settings_for": "Settings for {name}"
    },
    "signup": {
      "add_additional_users_later": "You may add additional users later.",
      "company_email": "Company email",
      "company_name": "Company name",
      "company_phone_number": "Company phone number",
      "company_representative": "Company representative",
      "first_name": "First name",
      "last_name": "Last name",
      "signup": "Sign up",
      "title": "Sign up as a seller at Organic Shopping",
      "your_email": "Your email",
      "your_personal_password": "Your personal password"
    },
    "suborder_card": {
      "items": "{suborder_count} items"
    },
    "suborder_details": {
      "add_box": {
        "add_box": "Add box",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "confirm_message": "You're about to add a new box. Are you sure?",
        "error": "Oops! Something went wrong.",
        "please_confirm": "Please confirm"
      },
      "all_items_picked": "All items picked",
      "amount": "Amount",
      "box": "Box",
      "expected_parcels_number": "Expected number of parcels",
      "item_id": "Item id",
      "order_shipped": "Order shipped",
      "picked": "Picked",
      "product": "Product",
      "shipping_label": "Shipping Label",
      "shipping_type": "Shipping type"
    },
    "suborders": {
      "add_product": "Start by adding some products",
      "awaiting_shipment": "Awaiting shipment",
      "empty_order": "No orders yet.",
      "new_orders": "New orders",
      "order_history": "Order history",
      "packing": "Packing"
    }
  };
  _exports.default = _default;
});