define("organic-shopping-shop-client/models/stripe-account", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    stripeAccountNumber: _emberData.default.attr("string"),
    chargesEnabled: _emberData.default.attr("boolean"),
    transfersEnabled: _emberData.default.attr("boolean"),
    authorizationCode: _emberData.default.attr("string"),
    // Only used during creation
    state: _emberData.default.attr("string"),
    // Only used during creation
    dashboardLink: _emberData.default.attr("string", {
      readOnly: true
    }),
    shop: _emberData.default.belongsTo("shop")
  });

  _exports.default = _default;
});