define("organic-shopping-shop-client/templates/authenticated/order-history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "72yuCIHQ",
    "block": "{\"symbols\":[\"suborder\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"model\",\"length\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-5\"],[8],[0,\"\\n        \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"order_history.shipped_orders\"],null],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"list-group-item list-group-item-action d-flex justify-content-between align-items-start\",\"authenticated.order-history.order\",[23,1,[]]]],{\"statements\":[[0,\"              \"],[1,[28,\"suborder-card\",null,[[\"suborder\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"col-7\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"col-lg-12 text-center mt-5\"],[8],[0,\"\\n        \"],[7,\"h3\",true],[8],[1,[28,\"t\",[\"order_history.empty_order\"],null],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[4,\"infinity-loader\",null,[[\"infinityModel\"],[[24,[\"model\"]]]],{\"statements\":[[4,\"unless\",[[24,[\"model\",\"reachedInfinity\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/order-history.hbs"
    }
  });

  _exports.default = _default;
});