define("organic-shopping-shop-client/initializers/ember-cli-bugsnag", ["exports", "organic-shopping-shop-client/config/environment", "bugsnag", "ember-cli-bugsnag/utils/bugsnag-configuration"], function (_exports, _environment, _bugsnag, _bugsnagConfiguration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'ember-cli-bugsnag',
    initialize: function initialize() {
      var configVariables = _environment.default.bugsnag || {};
      var releaseStage = _environment.default.bugsnag.releaseStage || _environment.default.environment; // Set currentRevision value as Bugsnag appVersion

      configVariables.appVersion = _environment.default.currentRevision || _environment.default.APP.version;

      if (typeof FastBoot === 'undefined') {
        new _bugsnagConfiguration.default(configVariables, releaseStage).apply(_bugsnag.default);
      }
    }
  };
  _exports.default = _default;
});