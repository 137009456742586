define("organic-shopping-shop-client/templates/authenticated/new-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3XzE6jV+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row justify-content-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col col-md-6\"],[8],[0,\"\\n      \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"products.new_product.add_product\"],null],false],[9],[0,\"\\n      \"],[1,[28,\"new-product-form\",null,[[\"product\",\"submit\"],[[24,[\"model\"]],[28,\"action\",[[23,0,[]],\"submit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/new-product.hbs"
    }
  });

  _exports.default = _default;
});