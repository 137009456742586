define("organic-shopping-shop-client/controllers/authenticated/new-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      submit: function submit(selectedProductType, changeset) {
        var _this = this;

        changeset.set("productType", selectedProductType);
        return changeset.save().then(function (savedProduct) {
          _this.transitionToRoute("authenticated.products.product", savedProduct.get("id"));
        });
      }
    }
  });

  _exports.default = _default;
});