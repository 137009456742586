define("organic-shopping-shop-client/utils/bugsnag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getUser = getUser;

  function getUser(owner) {
    var currentUser = owner.lookup("service:current-user").get("user");

    if (currentUser) {
      var _EmberGetProperties = Ember.getProperties(currentUser, "email", "id", "name"),
          email = _EmberGetProperties.email,
          id = _EmberGetProperties.id,
          name = _EmberGetProperties.name;

      return {
        email: email,
        id: id,
        name: name
      };
    }
  }
});