define("organic-shopping-shop-client/templates/components/suborder-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rn/Ml6JO",
    "block": "{\"symbols\":[\"suborder\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"list-group\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"suborders\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"list-group-item list-group-item-action d-flex justify-content-between align-items-start\",\"authenticated.suborders.suborder\",[23,1,[]]]],{\"statements\":[[0,\"      \"],[1,[28,\"suborder-card\",null,[[\"suborder\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/suborder-table.hbs"
    }
  });

  _exports.default = _default;
});