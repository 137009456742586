define("organic-shopping-shop-client/models/image", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    url: _emberData.default.attr("string", {
      readOnly: true
    }),
    position: _emberData.default.attr("number"),
    cOffset: _emberData.default.attr("number"),
    cAxis: _emberData.default.attr("string"),
    visible: _emberData.default.attr("boolean"),
    shop: _emberData.default.belongsTo("shop"),
    height: _emberData.default.attr("number"),
    width: _emberData.default.attr("number"),
    product: _emberData.default.belongsTo("product", {
      inverse: "images",
      async: true
    })
  });

  _exports.default = _default;
});