define("organic-shopping-shop-client/controllers/authenticated/payments/connect-to-stripe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    stripeCallbackPath: "",
    init: function init() {
      this._super.apply(this, arguments);

      this.set("stripeCallbackPath", this.router.urlFor("authenticated.payments.stripe-callback"));
    },
    stripeConnectExpressUrl: Ember.computed("stripeCallbackPath", "currentUser.user.shop.{email,name,phone}", "currentUser.user.{firstName,lastName}", "model.{clientId,state}", function () {
      var clientId = this.get("model.clientId");
      var state = this.get("model.state");
      var _window$location = window.location,
          protocol = _window$location.protocol,
          host = _window$location.host;
      var redirectUri = "".concat(protocol, "//").concat(host).concat(this.stripeCallbackPath);
      var params = {
        redirect_uri: redirectUri,
        client_id: clientId,
        state: state,
        "suggested_capabilities[]": "transfers",
        "stripe_user[business_type]": "company",
        "stripe_user[business_name]": this.get("currentUser.user.shop.name"),
        "stripe_user[phone_number]": this.get("currentUser.user.shop.phone"),
        "stripe_user[email]": this.get("currentUser.user.shop.email"),
        "stripe_user[first_name]": this.get("currentUser.user.firstName"),
        "stripe_user[last_name]": this.get("currentUser.user.lastName")
      };
      var paramString = Object.keys(params).map(function (k) {
        return "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(params[k]));
      }).join("&");
      return "https://connect.stripe.com/express/oauth/authorize?".concat(paramString);
    })
  });

  _exports.default = _default;
});