define("organic-shopping-shop-client/components/bg-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["imageType"],
    attributeBindings: ["style"],
    cropOffset: Ember.computed.alias("image.cOffset"),
    cropAxis: Ember.computed.alias("image.cAxis"),
    image: null,
    defaultType: "square",
    type: Ember.computed.oneWay("defaultType"),
    width: 0,
    height: 0,
    isDesktop: false,
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments); // eslint-disable-next-line ember/no-jquery


      Ember.$(window).off("resize.".concat(Ember.guidFor(this)));
    },
    didInsertElement: function didInsertElement() {
      this.scheduleUpdates(); // eslint-disable-next-line ember/no-jquery

      Ember.$(window).on("resize.".concat(Ember.guidFor(this)), Ember.run.bind(this, this.scheduleUpdates));
    },
    scheduleUpdates: function scheduleUpdates() {
      Ember.run.scheduleOnce("afterRender", this, this.updateThings);
    },
    updateThings: function updateThings() {
      if (this._state === "inDOM") {
        this.updateSize();
        this.updateIsDesktop();
      }
    },
    updateSize: function updateSize() {
      var scale = window.devicePixelRatio ? window.devicePixelRatio : 1; // eslint-disable-next-line ember/no-jquery

      var reportedWidth = this.$().width(); // eslint-disable-next-line ember/no-jquery

      var reportedHeight = this.$().height(); // eslint-disable-next-line ember/no-jquery

      if (scale > 1.5 && Ember.$(window).width() > 800) {
        scale = 1.5;
      }

      if (reportedWidth) {
        Ember.set(this, "width", Math.floor(reportedWidth * scale));
      }

      if (reportedWidth) {
        Ember.set(this, "height", Math.floor(reportedHeight * scale));
      }
    },
    updateIsDesktop: function updateIsDesktop() {
      // eslint-disable-next-line ember/no-jquery
      Ember.set(this, "isDesktop", Ember.$(window).width() > 1040); // Number copied from webclient config
    },
    style: Ember.computed("width", "height", "isDesktop", "image.{url,height,width}", "type", function () {
      var url = Ember.get(this, "image.url");

      if (!url) {
        return;
      }

      var width = Ember.get(this, "width");
      var height = Ember.get(this, "height");

      if (width === 0) {
        return "".htmlSafe();
      }

      if (height === 0) {
        height = width; // #notAHack
      }

      url = "url(".concat(url, ")"); // if (get(this, 'isDesktop') && width % 100 !== 0) {
      //   // Round up to closest 100 px
      //   width += 100 - width % 100;
      // }

      if (url.indexOf("OSWIDTH") > -1 && Ember.get(this, "type") !== "fullsize") {
        url = url.replace("OSWIDTH", width).replace("OSHEIGHT", height).replace(".jpg", "_o.jpg"); // Set image scaling to overflow
      } else if (Ember.get(this, "type") === "fullsize") {
        var imageHeight = Ember.get(this, "image.height");
        var imageWidth = Ember.get(this, "image.width");
        var padding = 100; // eslint-disable-next-line ember/no-jquery

        if (Ember.$(window).width() <= 640) {
          padding = 0;
        } // eslint-disable-next-line ember/no-jquery


        height = Math.min(Ember.$(window).height() - padding, imageHeight); // eslint-disable-next-line ember/no-jquery

        width = Math.min(Ember.$(window).width() - padding, imageWidth);
        var heightScale = height / imageHeight;
        var widthScale = width / imageWidth;
        var minScale = Math.min(heightScale, widthScale);
        height = Math.floor(imageHeight * minScale);
        width = Math.floor(imageWidth * minScale);
        var pixelRatio = window.devicePixelRatio ? window.devicePixelRatio : 1;
        url = url.replace("OSWIDTH", width * pixelRatio).replace("OSHEIGHT", height * pixelRatio);
      }

      var cropAxis = Ember.get(this, "cropAxis");
      var cropOffset = Ember.get(this, "cropOffset");
      var position;

      switch (cropAxis) {
        case "v":
          position = "50% ".concat(cropOffset, "%");
          break;

        case "h":
          position = "".concat(cropOffset, "% 50%");
          break;

        default:
          position = "50%";
      }

      var style = "background-image:".concat(url, ";background-position:").concat(position, ";");

      if (Ember.get(this, "type") === "fullsize") {
        style += "width:".concat(width, "px;height:").concat(height, "px;");
      }

      return style.htmlSafe();
    }),
    imageType: Ember.computed("type", function () {
      switch (Ember.get(this, "type")) {
        case "cover":
          return "image--cover";

        case "medium-feed":
          return "image--square";

        case "double":
          return "image--double";

        default:
          return "image";
      }
    }) // click() {
    //   this.sendAction('action', get(this, 'image'));
    // }

  });

  _exports.default = _default;
});