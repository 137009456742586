define("organic-shopping-shop-client/models/stripe-state", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    state: _emberData.default.attr("string"),
    clientId: _emberData.default.attr("string", {
      readOnly: true
    }),
    shop: _emberData.default.belongsTo("shop")
  });

  _exports.default = _default;
});