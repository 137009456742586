define("organic-shopping-shop-client/routes/authenticated/payments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return this.get("currentUser.user").get("shop").then(function (shop) {
        return shop.get("stripeAccount");
      });
    },
    afterModel: function afterModel(model) {
      if (model == null) {
        this.transitionTo("authenticated.payments.connect-to-stripe");
      }
    }
  });

  _exports.default = _default;
});