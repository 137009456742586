define("organic-shopping-shop-client/templates/authenticated/payments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lha4omM+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[1,[28,\"t\",[\"payments.index.payment_settings\"],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"payments.index.go_to_stripe_dashboard\"],null],false],[9],[0,\"\\n    \"],[7,\"a\",true],[11,\"href\",[24,[\"model\",\"dashboardLink\"]]],[10,\"target\",\"_blank\"],[8],[1,[28,\"t\",[\"payments.index.manage_stripe_account\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/payments/index.hbs"
    }
  });

  _exports.default = _default;
});