define("organic-shopping-shop-client/templates/components/product-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3W9/M6A3",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"class\",\"route\",\"model\"],[\"text-center\",\"authenticated.products.product\",[24,[\"product\"]]]],{\"statements\":[[0,\"  \"],[1,[28,\"bg-image\",null,[[\"image\"],[[24,[\"product\",\"imageMain\"]]]]],false],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"font-size: 20px;\"],[8],[0,\"\\n    \"],[1,[24,[\"product\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"color:#000;\"],[8],[0,\"\\n    \"],[1,[28,\"t\",[\"product_card.inventory_and_variants\"],[[\"inventory\",\"variant_count\"],[[24,[\"product\",\"inventory\"]],[24,[\"product\",\"productVariants\",\"length\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/components/product-card.hbs"
    }
  });

  _exports.default = _default;
});