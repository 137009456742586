define("organic-shopping-shop-client/models/parcel", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    maxWeight: _emberData.default.attr("string"),
    length: _emberData.default.attr("string"),
    height: _emberData.default.attr("string"),
    width: _emberData.default.attr("string")
  });

  _exports.default = _default;
});