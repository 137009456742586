define("organic-shopping-shop-client/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    currentUser: Ember.inject.service(),
    routeAfterAuthentication: "authenticated",
    intl: Ember.inject.service(),
    intercom: Ember.inject.service(),
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },
    sessionAuthenticated: function sessionAuthenticated() {
      var _this = this;

      this._super.apply(this, arguments);

      this._loadCurrentUser().then(function () {
        return _this.configureIntercom();
      });

      this.transitionTo("authenticated.products");
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      this.intl.setLocale(["en-us"]); // TODO: This should be dynamic

      return this.currentUser.load().catch(function () {
        return _this2.session.invalidate();
      });
    },
    configureIntercom: function configureIntercom() {
      var _this3 = this;

      this.get("currentUser.user.shop").then(function (shop) {
        _this3.setUser(shop);

        shop.on("didUpdate", _this3, _this3.updateUser);
      });
    },
    setUser: function setUser(shop) {
      var user = this.currentUser.user;
      var userObject = user.getProperties("email", "name", "id");
      var avatar = Ember.Object.create({
        type: "avatar",
        image_url: user.avatarUrl
      });
      userObject.avatar = avatar;
      userObject.phone = user.cellphone;
      var shopObject = shop.getProperties("name", "id");
      var customAttributes = Ember.Object.create({
        displayAddr: shop.displayAddr,
        email: shop.email,
        orgname: shop.orgname,
        phone: shop.phone
      });
      shopObject.custom_attributes = customAttributes;
      return this.intercom.setProperties({
        user: userObject,
        company: shopObject
      });
    },
    updateUser: function updateUser() {
      var _this4 = this;

      this.get("currentUser.user.shop").then(function (shop) {
        _this4.setUser(shop);
      });
    }
  });

  _exports.default = _default;
});