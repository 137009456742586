define("organic-shopping-shop-client/models/user-address", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo("user"),
    createdBy: _emberData.default.belongsTo("user"),
    updatedBy: _emberData.default.belongsTo("user"),
    deletedAt: _emberData.default.attr("date"),
    position: _emberData.default.attr("number"),
    addressLine: _emberData.default.attr("string"),
    addressLine2: _emberData.default.attr("string"),
    postalCode: _emberData.default.attr("string"),
    countryCode: _emberData.default.attr("string"),
    additionalInfo: _emberData.default.attr("string"),
    addressType: _emberData.default.attr("number"),
    deliverToInvoice: _emberData.default.attr("boolean"),
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string")
  });

  _exports.default = _default;
});