define("organic-shopping-shop-client/models/brand", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr("string"),
    url: _emberData.default.attr("string"),
    descr: _emberData.default.attr("string"),
    createdAt: _emberData.default.attr("date"),
    updatedAt: _emberData.default.attr("date"),
    products: _emberData.default.hasMany("product", {
      async: true
    })
  });

  _exports.default = _default;
});