define("organic-shopping-shop-client/adapters/user", ["exports", "organic-shopping-shop-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    urlForQueryRecord: function urlForQueryRecord(query) {
      if (query.me) {
        delete query.me;
        return "".concat(this._super(), "/me");
      }

      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});