define("organic-shopping-shop-client/components/new-product-form", ["exports", "organic-shopping-shop-client/validations/new-product", "organic-shopping-shop-client/transitions/fade"], function (_exports, _newProduct, _fade) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    NewProductValidations: _newProduct.default,
    productTypes: null,
    store: Ember.inject.service(),
    rules: function rules(_ref) {
      var firstTime = _ref.firstTime,
          newItems = _ref.newItems;

      if (firstTime) {
        return;
      }

      switch (!!newItems[0]) {
        case true:
          return _fade.fadeLeft;

        case false:
          return _fade.fadeRight;
      }
    },
    init: function init() {
      this._super.apply(this, arguments);

      this.set("productTypes", []);
    },
    actions: {
      searchAsync: function searchAsync(search) {
        return this.store.query("product-type", {
          filter: {
            search: search,
            synced_from_provider: false
          }
        });
      }
    }
  });

  _exports.default = _default;
});