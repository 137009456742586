define("organic-shopping-shop-client/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      // don't serialize readOnly relationships when updating
      if (snapshot.id !== null && relationship.options && relationship.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      // do not serialize the attribute!
      if (attribute.options && attribute.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});