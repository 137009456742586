define("organic-shopping-shop-client/templates/authenticated/payments/stripe-callback", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0LoAp+31",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[1,[28,\"t\",[\"payments.stripe_callback.title\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[8],[1,[28,\"t\",[\"payments.stripe_callback.info_line1\"],null],false],[9],[0,\"\\n\"],[7,\"p\",true],[8],[1,[28,\"t\",[\"payments.stripe_callback.info_line2\"],null],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "organic-shopping-shop-client/templates/authenticated/payments/stripe-callback.hbs"
    }
  });

  _exports.default = _default;
});