define("organic-shopping-shop-client/routes/authenticated/suborders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      return Ember.RSVP.hash({
        orders: this.store.query("suborder", {
          include: "items.product-variant.product,items.shipping-sku,order.user,shipments",
          filter: {
            status: "paid,packing,packed"
          }
        }),
        products: this.store.query("product", {
          page: {
            size: 1
          }
        })
      });
    },
    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model.orders,
        products: model.products
      });
    }
  });

  _exports.default = _default;
});