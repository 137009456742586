define("organic-shopping-shop-client/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr("string"),
    lastName: _emberData.default.attr("string"),
    name: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    source: _emberData.default.attr("string"),
    fbId: _emberData.default.attr("string"),
    fbToken: _emberData.default.attr("string"),
    fbUsername: _emberData.default.attr("string"),
    lastSaleAt: _emberData.default.attr("date"),
    username: _emberData.default.attr("string"),
    cellphone: _emberData.default.attr("string"),
    avatarUrl: _emberData.default.attr("string"),
    orders: _emberData.default.hasMany("order", {
      inverse: "user"
    }),
    shop: _emberData.default.belongsTo("shop")
  });

  _exports.default = _default;
});