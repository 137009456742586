define("organic-shopping-shop-client/helpers/money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    intl: Ember.inject.service(),
    compute: function compute(property) {
      if (property === null || isNaN(property)) {
        property = 0;
      }

      var sum = property;
      return this.intl.formatNumber(sum, {
        style: "currency",
        currency: "USD"
      });
    }
  });

  _exports.default = _default;
});