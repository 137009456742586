define("organic-shopping-shop-client/controllers/authenticated/suborders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newSuborders: Ember.computed("model.@each.status", function () {
      var suborders = this.model;
      return suborders.filterBy("status", "paid");
    }),
    packingSuborders: Ember.computed("model.@each.status", function () {
      var suborders = this.model;
      return suborders.filterBy("status", "packing");
    }),
    packedSuborders: Ember.computed("model.@each.status", function () {
      var suborders = this.model;
      return suborders.filterBy("status", "packed");
    })
  });

  _exports.default = _default;
});