define("organic-shopping-shop-client/components/delete-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    productToDelete: null,
    router: Ember.inject.service(),
    store: Ember.inject.service(),
    deleted: false,
    isLoading: false,
    actions: {
      deleteProduct: function deleteProduct(product) {
        var _this = this;

        product.set("deleted", true);
        this.set("isLoading", true);
        product.save().then(function () {
          _this.store.unloadRecord(product);

          _this.router.transitionTo("authenticated.products");

          _this.set("productToDelete", null);

          _this.set("isLoading", false);
        });
      }
    }
  });

  _exports.default = _default;
});