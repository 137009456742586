define("organic-shopping-shop-client/models/shop", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    visible: _emberData.default.attr("boolean"),
    name: _emberData.default.attr("string"),
    descr: _emberData.default.attr("string"),
    displayAddr: _emberData.default.attr("string"),
    email: _emberData.default.attr("string"),
    phone: _emberData.default.attr("string"),
    orgno: _emberData.default.attr("string"),
    orgname: _emberData.default.attr("string"),
    mvareg: _emberData.default.attr("boolean"),
    vatCode: _emberData.default.attr("number"),
    createdAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    updatedAt: _emberData.default.attr("date", {
      readOnly: true
    }),
    stripeAccount: _emberData.default.belongsTo("stripe-account"),
    stripeState: _emberData.default.belongsTo("stripe-state"),
    orderItems: _emberData.default.hasMany("order-item", {
      async: true
    })
  });

  _exports.default = _default;
});